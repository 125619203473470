
export const FighterData = [
    {
        image: require("./images/fighters/FighterImages/buffcharlie.jpeg"), 
        name: "Buff Richard"
    }, 
    {
        image: require("./images/fighters/FighterImages/brothersthree.jpeg"),
        name: "Brothers Three", 
    }, 
    {
        image: require("./images/fighters/FighterImages/madamepookie.jpeg"),
        name: "Madame Pookie", 
    }, 
    
    {
        image: require("./images/fighters/FighterImages/jorshua.jpeg"),
        name: "Jorshua", 
    }, 
    {
        image: require("./images/fighters/FighterImages/cringeboy.jpeg"),
        name: "CringeBoy2008", 
    }, 
    {
        image: require("./images/fighters/FighterImages/sklort.jpeg"),
        name: "Sklort", 
    }, 

    {
        image: require("./images/fighters/FighterImages/fartgamble.jpeg"),
        name: "Fart Gamble", 
    }, 
]